<template>
  <div class="home">
    <indexHeader activeIndex="1"></indexHeader>
    <!-- top  -->
    <div class="ad-top-swiper-wrapper">
      <!-- Swiper -->
      <div class="ad-top-swiper-container swiper-container swiper-container-initialized swiper-container-horizontal">
        <div class="swiper-wrapper">
          <div v-for="(aditem, index) in adTopList" :key="index" class="swiper-slide" :data-index="index">
            <img :src="aditem.adImg" class="ad-top-item-img">
            <div class="ad-top-item-title">
              <div>{{ aditem.adTitle }}</div>
            </div>≈
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="ad-top-swiper-pagination swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
        </div>

      </div>
    </div>

    <!-- news -->
    <div class="news-wrapper">
      <!-- 左侧 -->
      <div class="news-topics">
        <div class="news-topics-title">要素精选</div>
        <div v-for="(topic, index) in newsTopics" :key="index" class="news-topics-item" @click.stop="goToTopic(index)">

          <el-image class="news-topic-item-img" :src="topic.adImg" fit="fit"></el-image>
          <div class="news-topics-item-title">{{ topic.adTitle }}</div>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="news-list">
        <!-- -->
        <div class="news-list-title">最新</div>
        <div class="infinite-list">

          <div v-for="(item, index) in newList" :key="index" class="news-list-item" @click="goToNews(index)">
            <div class="news-list-item-time-wrapper">
              <div class="news-list-item-time-day">{{ item.adTime? item.adTime.substring(8,10):''}}</div>
              <div class="news-list-item-time-year">{{ item.adTime?item.adTime.substring(0,7):'' }}</div>
            </div>
            <img :src="item.adImg" class="news-list-item-image">
            <div class="news-list-item-title-wrapper">
              <div class="news-list-item-title">{{ item.adTitle }}</div>

            </div>
          </div>
        </div>
      </div>
      
      
    </div>
    <indexFooter></indexFooter>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import indexHeader from '@/components/indexHeader.vue';
import indexFooter from '@/components/indexFooter.vue';
import Swiper from '@/assets/swiper/5.4.5/swiper.min.js';
import '@/assets/swiper/5.4.5/swiper.min.css';

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    indexHeader,
    indexFooter
  },
  data() {
    return {
      swiperTop: {},//顶部轮播图
      adTopList: [], //顶部广告数组
      newsTopics: [],//话题数组
      newList: [],
      count: 0,
      pageData: {
        finished: false,
        loading: false,
        currentIndex: 0,
        rowsOfPage: 20
      }

    }
  },
  mounted() {
    let that = this;
    that.initAdTopList();
    that.initNewsTopicsList();
    that.load();
  },
  methods: {
    initAdTopList() {
      let that = this;
      that.$axios.post('/datafop/getAdlist', {
        adType: 'top'
      }).then(response => {
        // console.log(response.data);
        let resp = response.data;
        if (resp.retcode == '0000' && resp.data) {
          that.adTopList = resp.data;
          that.initSwiperTop();
        }

      }).catch(error => {
        console.error(error);
      }).finally(() => {
        console.log('finally')
      });
    },
    initSwiperTop() {
      let that = this;
      that.$nextTick(function () {
        that.swiperTop = new Swiper('.ad-top-swiper-container', {
          slidesPerView: 2,
          spaceBetween: 0,
          centeredSlides: true,
          loop: true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false
          },
          pagination: {
            el: '.ad-top-swiper-pagination',
            clickable: true,
          },
          on: {
            tap: function (event) {
              if (this.clickedSlide) {
                that.goToAd(this.clickedSlide.getAttribute("data-index"));
              }
            },
          },
        });
      });
    },
    goToAd(index) {
      let that = this;
      window.location.href = that.adTopList[index].adHref;
    },
    initNewsTopicsList() {
      let that = this;
      that.$axios.post('/datafop/getAdlist', {
        adType: 'topics'
      }).then(response => {
        // console.log(response.data);
        let resp = response.data;
        if (resp.retcode == '0000' && resp.data) {
          that.newsTopics = resp.data;

        }

      }).catch(error => {
        console.error(error);
      }).finally(() => {
        console.log('finally')
      });
    },
    goToTopic(index) {
      let that = this;
      window.location.href = that.newsTopics[index].adHref;
    },
    goToNews(index){
      let that = this;
      window.location.href = that.newList[index].adHref;
    },
    load() {
      if (this.pageData.finished) {
        console.log("finished");
        return;
      }
      if (this.pageData.loading) {
        console.log("loading");
        return;
      }
      this.loadNewsList();
    },
    loadNewsList() {
      let that = this;
      that.pageData.loading = true;
      that.$axios.post('/datafop/getAdlist', {
        adType: 'news',
        currentIndex: that.pageData.currentIndex + 1,
        rowsOfPage: that.pageData.rowsOfPage
      }).then(response => {
        // console.log(response.data);
        let resp = response.data;
        if (resp.retcode == '0000' && resp.data) {
          if (resp.data.length > 0) {
            that.pageData.currentIndex++;
            that.newList = that.newList.concat(resp.data);
          } else {
            that.pageData.finished = true;
          }

        }

      }).catch(error => {
        console.error(error);
      }).finally(() => {
        that.pageData.loading = false;
      });
    },
  },
}
</script>
<style scoped>
.ad-top-swiper-wrapper {
  padding: 18px 0;
}

.ad-top-swiper-container {
  width: 100vw;
  height: 19vw;
}

.ad-top-swiper-container .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: gray;
  border: 1px solid black;
  transition: 300ms;
  transform: scale(0.9);
  position: relative;
}

.ad-top-swiper-container .swiper-slide-active,
.ad-top-swiper-container .swiper-slide-duplicate-active {
  transform: scale(1);
}
.ad-top-swiper-pagination {
  bottom:0px;
}
.ad-top-swiper-pagination>>>.swiper-pagination-bullet {
  width: 12px !important;
  height: 4px !important;
  border: 0;
  border-radius: 0;
  background: black;
  opacity: 0.5;
}

.ad-top-swiper-pagination>>>.swiper-pagination-bullet-active {
  background: #fe4c04;
  opacity: 1;
}

.ad-top-item-img {
  width: 100%;
  height: 100%;
}

.ad-top-item-title {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  padding: 9px;
  width: 100%;

}

.ad-top-item-title div {

  display: -webkit-box;

  font-size: 18px;
  line-height: 1.6;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 57px;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  text-align: left;
  color: white;
}

.news-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  padding:0 5vw
}

.news-topics {
  width: 20vw;
  padding: 20px;
  background: linear-gradient(131deg, #e44303, #2131c5 50%);
}

.news-topics-title {
  font-size: 18px;
  font-weight: bold;
  color: white;
  padding: 5px 0;
  border-bottom: 1px solid white;
}

.news-topics-item {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid white;
}

.news-topics-item-title {
  font-size: 18px;
  margin-left: 5px;
  flex: 1;
  color: white;
}

.news-topic-item-img {
  width: 60px;
  height: 60px;
  border-radius: 5px;
}

.news-topics-item:hover {
  cursor: pointer;
}

.news-topics-item:hover .news-topics-item-title {
  font-weight: bold;

}

.news-list {
  width: 60vw;
  min-height: 50vh;
  background: transparent;
}
.news-list-title{
  padding:20px ;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
}
.infinite-list {
  width: 100%;
  padding: 10px;
}
.news-list-item{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom:20px;
}
.news-list-item-image{
  width: 20vw;
  height: 11vw;
  object-fit: cover;
}
.news-list-item-title{
  font-size: 20px;
  word-break: break-all;
  line-height: 1.4;
  text-align:left;
  font-weight: bold;
  
}
.news-list-item-title:hover {
  cursor: pointer;
  color:#e44303;
}
.news-list-item-title-wrapper{
  display: flex;
  align-items: center;
  padding:10px;
  min-height: 11vw;
  flex:1;
  border-bottom: 1px solid black;
    margin-left: 20px;
}
.news-list-item-time-wrapper{
  width: 60px;
  height: 60px;
  background: linear-gradient(131deg, #e44303, #2131c5 50%);
  margin-right:10px;
  color:white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.news-list-item-time-day{
  font-size: 2em;
}

</style>
