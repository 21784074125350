<template>
  <div class="index-footer">
    <div>Copyright © 2023-present</div>
  </div>
</template>

<script>
export default {
  name: 'indexFooter',
  props: {
    
  },
  // data() 返回的属性将会成为响应式的状态
  // 并且暴露在 `this` 上
  data() {
    return {
      
    }
  },

  // methods 是一些用来更改状态与触发更新的函数
  // 它们可以在模板中作为事件处理器绑定
  methods: {
    
  },

  // 生命周期钩子会在组件生命周期的各个不同阶段被调用
  // 例如这个函数就会在组件挂载完成后被调用
  mounted() {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.index-footer{
  margin-top:20px;
  padding:20px;
  width: 100vw;
  min-height: 10vh;
  background: #f7fbfd;
}
</style>
