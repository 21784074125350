<template>
  <div>
    <el-row>
      <el-col :span="1"><div class="grid-content"> </div></el-col>
      <el-col :span="5">
        <div class="header-title">
          <el-image
          class="title-img"
      :src="require('@/assets/logo.png')"
      fit="fit"></el-image>
         <div class="header-title-txt">数据要素社</div>
        </div>
      </el-col>
     
      <el-col :span="18" class="menu-col" >
        <el-menu :default-active="activeIndex" class="header-menu" 
        active-text-color="#fe4c04"  
        mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">新闻资讯</el-menu-item>
          <el-submenu index="3">
            <template slot="title">关于我们</template>
            <el-menu-item index="3-1">关于我们</el-menu-item>
            <el-menu-item index="3-2">联系我们</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>
    <!-- <el-divider class="header-divider"></el-divider> -->
  </div>
</template>

<script>
export default {
  name: 'indexHeader',
  props: {
    activeIndex:""
  },
  // data() 返回的属性将会成为响应式的状态
  // 并且暴露在 `this` 上
  data() {
    return {
      
    }
  },

  // methods 是一些用来更改状态与触发更新的函数
  // 它们可以在模板中作为事件处理器绑定
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  },

  // 生命周期钩子会在组件生命周期的各个不同阶段被调用
  // 例如这个函数就会在组件挂载完成后被调用
  mounted() {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.grid-content{
  min-height: 60px;
  
}
.title-img{
  height: 60px;
  width: 60px;
}
.header-title{
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-title-txt{
  font-size: 20px;
  font-weight: bold;
}
.header-divider{
  margin:5px 0;
}
.menu-col {
  display: flex;
  justify-content: center;
}
.menu-col .header-menu{
  border-bottom:none;
}
</style>
