import axios from "axios";

//创建一个axios实例
const axiosInstance = axios.create({
    baseURL:process.env.VUE_APP_API_URL,
    timeout:60000,
    headers:{
        'Content-Type':'application/json;charset=UTF-8'
    }
});

export default axiosInstance;
